<template>
    <v-select
        v-bind:items="directionOptions"
        v-bind:rules="directionRules"
        v-on:change="clearSpot()"
        v-model="direction"
        label="Direction *"
    />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import directions from '../constants/direction';
import rules from '../constants/rules';

export default {
  data: () => ({
    directionRules: [
      rules.rules.required,
    ],
    directionOptions: [directions.IN, directions.OUT],
  }),
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    direction: {
      get() {
        return this.gateEntry.direction;
      },
      set(direction) {
        this.setDirection(direction);
      },
    },
  },
  methods: {
    ...mapActions('gate', [
      'setDirection',
      'setLocationId',
      'setLocationDisplayName',
      'setLocationGroups',
      'setLocationType',
    ]),
    clearSpot() {
      if (this.direction !== directions.IN) {
        this.setLocationId('');
        this.setLocationDisplayName('');
        this.setLocationGroups([]);
        this.setLocationType('');
      }
    },
  },
};
</script>
