<template>
  <v-form ref="equipmentEntryForm">
    <v-container fluid class="ma-0 pa-0">
      <v-row>
        <EquipmentTypeEntry />
      </v-row>
      <v-row>
        <EquipmentNumberEntry
          v-bind:requiresValidation="true"
          v-bind:allowAutocomplete="true" />
      </v-row>
      <v-row v-if="hasEnteredValidEquipmentNumber()">
        <EquipmentStateEntry id="equipmentStateEntry" />
      </v-row>
      <v-row v-if="hasEnteredValidEquipmentNumber()">
        <EquipmentSizeEntry id="equipmentSizeEntry" />
      </v-row>
      <v-row class="pt-0 mt-0" v-if="hasEnteredValidEquipmentNumber()">
        <EquipmentCarrierEntry id="equipmentCarrierEntry" />
      </v-row>
      <v-row class="pl-0" v-if="hasEnteredValidEquipmentNumber()">
        <v-col class="pl-0 pt-0 mt-0">
          <EquipmentBookingNumberEntry id="equipmentBookingNumberEntry" />
        </v-col>
        <v-col class="pl-0 pt-0 mt-0">
          <EquipmentCustomerEntry id="equipmentCustomerEntry"/>
        </v-col>
      </v-row>
      <v-row v-if="hasEnteredValidEquipmentNumber()">
        <v-col class="pl-0 pt-0" md="3">
          <v-text-field
            :disabled="true"
            :rules="equipmentLocationRules"
            v-model="spot"
            label="Spot Number *"
          ></v-text-field>
        </v-col>
        <v-col class="pt-5" md="1">
          <v-btn
            id="findSpot"
            raised
            color="primary"
            style="color: white"
            v-on:click="findSpotClicked"
            v-bind:disabled="isFindSpotDisabled"
          >
            Find a spot
          </v-btn>
          <SpotSelection @spot-selected="spotSelected" v-if="!isFindSpotDisabled" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import rules from '../constants/rules';
import ruleService from '../services/ruleService';
import equipmentMapper from '../mappers/equipmentMapper';
import SpotSelection from './spotSelectionDialog.vue';
import EquipmentTypeEntry from './equipmentTypeEntry.vue';
import EquipmentStateEntry from './equipmentStateEntry.vue';
import EquipmentNumberEntry from './equipmentNumberEntry.vue';
import EquipmentSizeEntry from './equipmentSizeEntry.vue';
import EquipmentBookingNumberEntry from './equipmentBookingNumberEntry.vue';
import EquipmentCustomerEntry from './equipmentCustomerEntry.vue';
import EquipmentCarrierEntry from './equipmentCarrierEntry.vue';

export default {
  components: {
    SpotSelection,
    EquipmentTypeEntry,
    EquipmentStateEntry,
    EquipmentNumberEntry,
    EquipmentSizeEntry,
    EquipmentBookingNumberEntry,
    EquipmentCustomerEntry,
    EquipmentCarrierEntry,
  },
  data: () => ({
    equipmentLocationRules: [
      rules.rules.required,
    ],
    carrierOption: 'SCAC',
  }),
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    ...mapGetters('account', ['isAdmin']),
    ...mapGetters('location', ['isLoading', 'showEditEquipmentDialog']),
    ...mapGetters('expectedGateActivity', ['selectedGateActivity']),
    ...mapGetters('equipment', ['selectedExistingEquipment']),
    spot: {
      get() {
        return this.gateEntry.location.displayName;
      },
      set(spot) {
        this.setLocationDisplayName(spot);
      },
    },
    isFindSpotDisabled() {
      if (this.isLoading || (!this.isAdmin && this.showEditEquipmentDialog)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('gate', [
      'setLocationDisplayName',
      'setLocationId',
      'setLocationGroups',
      'setLocationType',
      'setLocationDisplayName',
      'setEquipment',
      'setEquipmentNumber',
      'setEquipmentState',
      'setEquipmentSize',
      'setCarrierSCAC',
      'setCustomer',
    ]),
    ...mapActions({ resetGateState: 'gate/resetState' }),
    ...mapActions('location', ['setShowSpotSelectionDialog']),
    ...mapActions('equipment', ['setShowVerifyEquipmentDialog']),
    ...mapActions({ raiseInformation: 'alert/raiseInformation' }),
    ...mapActions({ resetExpectedGateActivity: 'expectedGateActivity/resetState' }),
    ...mapActions('check', ['getLostEquipment']),
    findSpotClicked() {
      this.setShowSpotSelectionDialog(true);
    },
    spotSelected(location) {
      this.setLocationId(location.id);
      this.setLocationGroups(location.groups);
      this.setLocationType(location.type);
      this.setLocationDisplayName(location.displayName);
    },
    verifyEquipmentDialogClosed() {
      this.$refs.equipmentEntryForm.resetValidation();
    },
    hasEnteredValidEquipmentNumber() {
      let hasValidEquipmentNumber = true;
      const rules = ruleService.getEquipmentNumberRules(this.gateEntry.equipment.type);
      rules.forEach((rule) => {
        if (rule(this.gateEntry.equipment.number) !== true) {
          hasValidEquipmentNumber = false;
        }
      });
      return hasValidEquipmentNumber;
    },
    async loadLostEquipment() {
      await this.getLostEquipment();
    },
    clearForm() {
      this.resetState();
    },
  },
  mounted() {
    this.loadLostEquipment();
  },
  beforeDestroy() {
    this.setEquipment(_.cloneDeep(equipmentMapper.defaultEquipment));
    this.resetExpectedGateActivity();
  },
};
</script>

<style>
/* hide the "scrim", it's pointless */
.v-overlay--active .v-overlay__scrim {
    display: none;
}
/* style the overlay container as required */
.v-overlay--active {
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.500);
}
/* if you have an auto dark theme
   for prefers-color-scheme: dark
   I find the 0.8 too dark
*/
@media (prefers-color-scheme: dark) {
    .v-overlay--active {
        background: rgba(0, 0, 0, 0.5);
    }
}
</style>
