<template>
    <v-container>
        <EquipmentEntry ref="equipmentEntry"/>
        <VerifyEquipmentEntryDialog
            v-on:verify-equipment-dialog-closed="verifyEquipmentDialogClosed"/>
        <v-row v-if="hasEnteredValidEquipmentNumber()">
            <v-col>
                <SubmitButton
                    v-if="!showEditEquipmentDialog"
                    v-on:submit="submit"/>
                <v-btn
                    color="secondary"
                    v-on:click="clearForm"
                    v-if="!showEditEquipmentDialog"
                    class="ml-4"
                    min-width="8vw">
                    Close
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ruleService from '../services/ruleService';
import EquipmentEntry from './equipmentEntry.vue';
import VerifyEquipmentEntryDialog from './verifyEquipmentEntryDialog.vue';
import SubmitButton from './submitButton.vue';

export default {
  data: () => ({
    matchLength: 0,
  }),
  components: {
    EquipmentEntry,
    VerifyEquipmentEntryDialog,
    SubmitButton,
  },
  computed: {
    ...mapGetters('location', ['isLoading', 'showEditEquipmentDialog']),
    ...mapGetters('gate', ['gateEntry']),
    ...mapGetters('equipment', ['showEquipmentNumberVerification']),
  },
  methods: {
    ...mapActions('gate', ['resetState']),
    ...mapActions('equipment', ['setShowVerifyEquipmentDialog']),
    verifyEquipmentDialogClosed() {
      this.$refs.equipmentEntry.$refs.equipmentEntryForm.resetValidation();
    },
    verificationResponse(response) {
      if (response === true) {
        this.setEquipmentNumber(this.selectedGateActivity.equipment.number);
        this.setEquipmentState(this.selectedGateActivity.equipment.state);
        this.setEquipmentSize(this.selectedGateActivity.equipment.size);
        this.setCarrierSCAC(this.selectedGateActivity.equipment.carrier.scac);
        this.setCustomer(this.selectedGateActivity.equipment.customer.name);
        this.$refs.equipmentEntry.$refs.equipmentEntryForm.validate();
      } else if (response === false) {
        this.raiseInformation('Your input did not match the equipment number chosen. Please make sure to choose the correct equipment number.');
      }
    },
    // TODO Mason - What is the best way to reuse this logic? It's also in the equipment entry
    hasEnteredValidEquipmentNumber() {
      let hasValidEquipmentNumber = true;
      const rules = ruleService.getEquipmentNumberRules(this.gateEntry.equipment.type);
      rules.forEach((rule) => {
        if (rule(this.gateEntry.equipment.number) !== true) {
          hasValidEquipmentNumber = false;
        }
      });
      return hasValidEquipmentNumber;
    },
    submit() {
      const isValid = this.$refs.equipmentEntry.$refs.equipmentEntryForm.validate();
      if (isValid) {
        this.setShowVerifyEquipmentDialog(true);
      }
    },
    clearForm() {
      this.resetState();
    },
  },
};
</script>

<style>
/* hide the "scrim", it's pointless */
.v-overlay--active .v-overlay__scrim {
    display: none;
}
/* style the overlay container as required */
.v-overlay--active {
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.500);
}
/* if you have an auto dark theme
   for prefers-color-scheme: dark
   I find the 0.8 too dark
*/
@media (prefers-color-scheme: dark) {
    .v-overlay--active {
        background: rgba(0, 0, 0, 0.5);
    }
}
</style>
