<template>
    <v-form ref="gateOutForm">
        <v-container fluid >
            <v-row>
                <EquipmentTypeEntry />
            </v-row>
            <v-row>
                <EquipmentNumberEntry
                  v-bind:requiresValidation="true"
                  v-bind:allowAutocomplete="true" />
            </v-row>
            <v-row v-if="hasEnteredValidEquipmentNumber()">
              <v-col>
                  <SubmitButton v-on:submit="submit"/>
                  <v-btn
                      color="secondary"
                      v-on:click="clearForm"
                      class="ml-4"
                      min-width="8vw">
                      Close
                  </v-btn>
              </v-col>
            </v-row>
            <VerifyEquipmentEntryDialog v-on:verify-equipment-dialog-closed="verifyEquipmentDialogClosed"/>
        </v-container>
    </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import equipmentMapper from '../mappers/equipmentMapper';
import EquipmentTypeEntry from './equipmentTypeEntry.vue';
import EquipmentNumberEntry from './equipmentNumberEntry.vue';
import SubmitButton from './submitButton.vue';
import VerifyEquipmentEntryDialog from './verifyEquipmentEntryDialog.vue';
import ruleService from '../services/ruleService';

export default {
  components: {
    EquipmentTypeEntry,
    EquipmentNumberEntry,
    SubmitButton,
    VerifyEquipmentEntryDialog,
  },
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    ...mapGetters('equipment', ['showEquipmentNumberVerification']),
  },
  methods: {
    ...mapActions('gate', ['setEquipment']),
    ...mapActions('equipment', ['setShowVerifyEquipmentDialog']),
    hasEnteredValidEquipmentNumber() {
      let hasValidEquipmentNumber = true;
      const rules = ruleService.getEquipmentNumberRules(this.gateEntry.equipment.type);
      rules.forEach((rule) => {
        if (rule(this.gateEntry.equipment.number) !== true) {
          hasValidEquipmentNumber = false;
        }
      });
      return hasValidEquipmentNumber;
    },
    clearForm() {
      this.setEquipment(_.cloneDeep(equipmentMapper.defaultEquipment));
    },
    submit() {
      const isValid = this.$refs.gateOutForm.validate();
      if (isValid) {
        this.setShowVerifyEquipmentDialog(true);
      }
    },
    verifyEquipmentDialogClosed() {
      this.$refs.gateOutForm.resetValidation();
    },
  },
};

</script>

<style>
/* hide the "scrim", it's pointless */
.v-overlay--active .v-overlay__scrim {
    display: none;
}
/* style the overlay container as required */
.v-overlay--active {
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.500);
}
/* if you have an auto dark theme
   for prefers-color-scheme: dark
   I find the 0.8 too dark
*/
@media (prefers-color-scheme: dark) {
    .v-overlay--active {
        background: rgba(0, 0, 0, 0.5);
    }
}
</style>
