<template>
  <v-container class="ma-0 pa-0">
    <GateDirectionEntry />
    <GateIn v-if="isGateIn" />
    <GateOut v-else />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Direction from '../constants/direction';
import EquipmentMapper from '../mappers/equipmentMapper';
import GateDirectionEntry from '../components/gateDirectionEntry.vue';
import GateIn from '../components/gateIn.vue';
import GateOut from '../components/gateOut.vue';

export default {
  components: {
    GateDirectionEntry,
    GateIn,
    GateOut,
  },
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    isGateIn() {
      return this.gateEntry.direction === Direction.IN;
    },
    direction() {
      return this.gateEntry.direction;
    },
  },
  methods: {
    ...mapActions('gate', ['setEquipment']),
    ...mapActions('equipment', ['setShowEquipmentNumberVerification']),
    ...mapActions('expectedGateActivity', ['getExpectedGateActivities']),
    ...mapActions('move', ['getPendingMoves']),
  },
  created() {
    this.setShowEquipmentNumberVerification(false);
    this.getExpectedGateActivities();
    this.getPendingMoves();
  },
  watch: {
    direction() {
      this.setEquipment(_.cloneDeep(EquipmentMapper.defaultEquipment));
    },
  },
};
</script>
